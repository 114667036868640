import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pollen is a contributor rank used to recognize contributions to 1hive’s discord, discourse, and github communities, and reward these contributions with weekly distributions of Honey.`}</p>
    <h3 {...{
      "id": "how-do-i-participate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-participate",
        "aria-label": "how do i participate permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I participate`}</h3>
    <p>{`As soon as you start interacting on 1hive’s discord, discourse, and github communities you’ll start earning Pollen.`}</p>
    <p>{`Pollen is computed using Sourcecred to create and analyze a graph of interactions between community members. While not a perfect representation of the value of contributions, Pollen can help reward the interactions that are so important but too granular to warrant speccing out and reviewing a proposal.`}</p>
    <p>{`In order to receive weekly pollen distributions you’ll need to register your accounts on supported platforms and link them to your xDai address. You can do this by posting in 1hive Discord’s `}<inlineCode parentName="p">{`#🐛onboarding`}</inlineCode>{` channel:`}</p>
    <pre><code parentName="pre" {...{}}>{`#🏵pollen
github: justabee
discourse: justabee
xDai: 0x0...000
`}</code></pre>
    <p>{`Replacing with your accounts.`}</p>
    <p>{`If you have questions, are interested in how pollen is calculated, or auditing the distributions, just hop on the `}<a parentName="p" {...{
        "href": "https://discord.com/invite/D6pZzxU"
      }}><inlineCode parentName="a">{`#🏵pollen`}</inlineCode></a>{` channel.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      